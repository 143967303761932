import React, { useEffect } from "react";
import { GoogleMap, GroundOverlay, useJsApiLoader, LatLng } from "@react-google-maps/api";
import { getDateTimeStr } from "./utils.js";

const containerStyle = {
  width: "100%",
  height: "100%",
  position: "absolute"
}

const bounds = {
  north: 52.77,
  south: 21,
  east: -60.71,
  west: -134.25
}

const mapOptions = {
  disableDefaultUI: true
}

function Map({center, zoom, sliderVal, urls, dateTimes}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAjHzoANpbEj0w0_vAPghBQ1x3MHZJa5X4"
  });

  const [map, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map);
  // });

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onDragEnd = () => {

  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={zoom}
      center={center}
      // onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
      onDragEnd={onDragEnd}
    >
      {urls.map((url, idx) => {
        if (idx == sliderVal) {
          return <GroundOverlay url={url} bounds={bounds} key={url}/>
        }
        else {
          return null;
        }
      })}
    </GoogleMap>
  ) : <></>;
}

export default React.memo(Map);