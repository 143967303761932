import logo from './logo.svg';
import './Main.css';
import Map from "./Map.js";
import { getDateTimeStr } from "./utils.js"
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';


function getDateTimes() {
  let dateTimes = [];

  const currentDateTime = new Date();
  currentDateTime.setMinutes(0);
  currentDateTime.setSeconds(0);

  const maxSteps = 48 - currentDateTime.getUTCHours() % 6 - 6;
  const period = 1;

  for (let i = 0; i < maxSteps; i++) {
    const dateTime = new Date(currentDateTime.getTime() + 1000 * 60 * 60 * period * i);
    dateTimes.push(dateTime);
  }

  return dateTimes; 
}

function fetchImages(urls) {
  return new Promise(() => {
    for (let url of urls) {
      fetch(url, {mode: "no-cors"});
    }
  })
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}


const dateTimes = getDateTimes();

const baseUrl = "https://haze.airfire.org/ext/HRRR/3km/current/images/hourly/hourly_";
const urls = dateTimes.map(dateTime => `${baseUrl}${getDateTimeStr(dateTime)}.png`);

// ---------------------------------------------

function Main({ center, zoom }) {
  const [imagesLoaded, setImagesLoaded] = React.useState(false);
  const [sliderVal, setSliderVal] = React.useState(0);
  const [playing, setPlaying] = React.useState(false);
  const [infoActive, setInfoActive] = React.useState(false);
  const abort = React.useRef(false);

  useEffect(() => {
    fetchImages(urls).then(() => setImagesLoaded(true));
  }, [])

  function changeSlider(event) {
    setSliderVal(parseInt(event.target.value));
  }

  async function playAnimation() {
    setPlaying(true);

    while (sliderVal < urls.length) {
      if (abort.current) {
        abort.current = false;
        break;
      }

      await sleep(100);

      setSliderVal((prev) => {
        if (prev === urls.length - 1) {
          return 0
        }
        return prev + 1;
      });
    }
  }

  function stopAnimation() {
    setPlaying(false);
    abort.current = true;
  }

  const info = (
    <div className="info-container">
      <h2>What is SmokeMap?</h2>
      <p>SmokeMap </p>
      <h2>What model is being used?</h2>
      <p>SmokeMap uses the HRRR model for it forecast. It is an advanced atmospheric model that uses numerical analysis and boundary conditions to generate forecasts up to 48 hours in the future.</p>
      <h2>How accurate is the forecast?</h2>
    </div>
  )

  function infoBtnClick() {
    if (infoActive) {
      setInfoActive(false);
    }
    else {
      setInfoActive(true);
    }
  }

  return (
    <div className="container">
      <Map center={center} zoom={zoom} urls={urls} sliderVal={sliderVal}/>
      {/* <div className="controls-container"> */}
        {/* <div className="slider-container"> */}
      {/* <div className="slider-bg" /> */}
      <input className="slider" type="range" min={0} max={urls.length-1} value={sliderVal} onChange={changeSlider}/>
      <p className="dateTime">{dateTimes[sliderVal].toLocaleString()}</p>
      <div className="slider-labels">
        {[...Array(dateTimes.length-1)].map(() => <div className="slider-tick"/>)}
      </div>
        {/* </div> */}
      {playing ? <button className="animateBtn" onClick={stopAnimation} title="Pause animation">&#8545;</button>
      : <button className="animateBtn" onClick={playAnimation} title="Play animation">&#9654;</button>
      }
      {/* </div> */}
      <button className="infoBtn" style={{right: infoActive ? "440px" : "0px"}} title="Information" onClick={infoBtnClick}>i</button>
      {/* <a href="#info" className="infoBtn2" onClick={() => setInfoActive(true)}>&#8659; More Information &#8659;</a> */}
      {infoActive ? info : null}
    {/* <div style={{display: infoActive ? "block" : "none"}} className="info-container-2" id="info"></div> */}
    </div>
  )
}

export default Main;
