import React from "react";
import { Helmet } from "react-helmet";
import Main from "../Main";

const center =  {
  lat: 47.60621,
  lng: -122.332071,
};

function Seattle() {
  return (
    <div>
      <Helmet>
        <title>Seattle Smoke Forecast Map | SmokeLook</title>
        <meta type="description" content="Wildfire smoke forecast map for Seattle, Washington."/>
      </Helmet>
      <Main center={center} zoom={8}/>
    </div>
  )
}

export default Seattle;