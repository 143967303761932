import React from "react";
import { Helmet } from "react-helmet";
import Main from "../Main";

const center =  {
  lat: 39,
  lng: -97,
};

function Home() {
  return (
    <div>
      <Helmet>
        <title>Smoke Map</title>
        <meta type="description" content="Wildfire smoke forecast" />
      </Helmet>
      <Main center={center} zoom={4}/>
    </div>
  )
}

export default Home;